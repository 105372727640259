.loginBackground {
  background-image: url("../../assets/images/background_1.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden !important;
}

.logTitle, .logSubtitle {
  text-align: center;
  font-weight: bold;
}

.logTitle {
    font-size: 2rem;
    margin-top: 0.5em;
    margin-bottom: 0.01em;
}

.logSubtitle {
    font-size: 1.15rem;
}

.loginEmail, .loginPassword {
    border-radius: 0.7em;
    padding: 0.5em;
    font-weight: bold
}

.form-control:focus {
    border-color: unset;
    box-shadow: unset;
    border: 2px solid #000;
}

.logInBtn {
    background-color: var(--primary-blue) !important;
    color: #fff !important;
}

.signUpBtn, .cancelBtn {
    border: 2px solid var(--primary-blue) !important;
    color: var(--primary-blue) !important;
}