.dropToggle {
  border: none;
  padding: 0.25em !important;
}

.dropToggle:focus,
.dropToggle:active {
  outline: 2px solid #4b4870 !important ;
}

.dropDownItems {
  display: flex;
  align-items: center;
  justify-content: right;
  padding: 10px;
}

.firstItem:hover {
  background-color: #171b45;
  color: white;
  border-radius: 10px 0 0 0;
}

.firstItem:hover .dropDownImage {
  fill: white;
}

.secondItem:hover {
  background-color: #171b45;
  color: white;
}

.thirdItem:hover {
  background-color: #171b45;
  color: white;
  border-radius: 0 0 0 10px;
}

.dropDownImage {
  color: black;
  margin-right: 10px;
}
