:root {
  --primary-blue: #2f00fe;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "roobertRegular";
  src: url("./assets/font/Roobert-Regular.otf");
}

@font-face {
  font-family: "roobertSemiBold";
  src: url("./assets//font/Roobert-SemiBold.otf");
}

html {
  scrollbar-width: thin !important;
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #c9c8c85b;
  }
}

body {
  font-family: "roobertRegular", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "roobertSemiBold", sans-serif;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

main {
  flex-grow: 1;
}

.content-container {
  width: 98%;
  margin: 0px auto;
}

.custom-btn {
  display: block;
  margin: auto;
  width: 100%;
  color: #000;
  font-family: roobertRegular;
  background-color: #fff;
  font-weight: bold;
  text-align: center;
  padding: 0.5em;
  border-radius: 36px;
  transition-duration: 0.2s;
  text-decoration: none;
  border: none;
}

.btn-basic {
  display: block;
  margin: auto;
  width: 100%;
  font-family: roobertRegular;
  font-weight: bold;
  text-align: center;
  padding: 0.7em;
  border-radius: 0.375rem;
  transition-duration: 0.2s;
  text-decoration: none;
  border: none;
}

.btn-outline {
  background-color: white;
  color: #2f00fe;
  border: 2px solid #2f00fe;
}

.btn-outline-dark {
  background-color: white;
  color: #1a1c46;
  border: 2px solid #1a1c46;
}
.btn-outline-light {
  background-color: white;
  color: #4b4870;
  border: 2px solid #4b4870;
}

.btn-outline:hover {
  background-color: #2f00fe;
  color: white;
}
.btn-outline-dark:hover {
  background-color: #1a1c46;
  color: white;
}
.btn-outline-light:hover {
  background-color: #4b4870;
  color: white;
}

.btn-blue {
  background-color: var(--primary-blue) !important;
  color: #fff !important;
}

.btn-clear {
  background-color: #4b4870 !important;
}

.skill-select-box .searchWrapper {
  border: 0;
  padding: 0.7em 0em;
  font-size: 13px;
}
