.upload-file-button {
  background-color: #2f00fe !important;
  color: rgb(253, 253, 253) !important;
  padding: 4px !important;
  border-radius: 0.3em !important;
}

.upload-file-button:hover {
  background-color: #2b00e8 !important;
  cursor: pointer !important;
}

.profile-pic-uploader:hover {
  cursor: pointer !important;
}

.profile-pic-uploader {
  width: 150px !important;
  border-radius: 50% !important;
  height: 150px !important;
  overflow: hidden !important;
  margin: 0 auto !important; 
  background-color: #f0f0f0 !important;
}
