.find-jobs-container {
  margin-top: 1em;
  margin-bottom: 3em;
}

.job-list,
.job-desc-container {
  max-height: 67.5vh;
  min-height: 38.3vh;
  overflow-y: auto;
}

.btn-apply {
  background-color: #2f00fe;
  color: white;
  width: auto !important;
  margin: 0 !important;
  padding: 0.1em 1em !important;
}

.btn-withdraw {
  width: auto !important;
  margin: 0 !important;
  padding: 0.1em 1em !important;
}

.pagination {
  padding: 0.5em !important;
  background-color: rgba(128, 128, 128, 0.123);
  border-top-left-radius: 1em;
}

.pagination-button {
  border: 0;
  background-color: rgba(167, 166, 166, 0.295);
  margin-left: 1em;
  border-radius: 50%;
  width: 26px;
  height: 26px;
}

.svg-container {
  width: 100%;
  height: 100%;
}
.selected-job {
  background-color: rgba(167, 166, 166, 0.295) !important;
}

.withdraw-status {
  background-color: #a9a9a9;
}

.rejected-status {
  background-color: #ff4d4d;
}

.applied-status {
  background-color: #ffa500;
}

.approved-status {
  background-color: #4caf50;
}
.hired-status {
  background-color: #007bff;
}
.pending-status {
  background-color: #ffd700;
}
.completed-status {
  background-color: #4caf50;
}
.incompleted-status {
  background-color: #ff6347;
}
.scheduled-status {
  background-color: #4682b4;
}
