.navBar {
  background-color: #171c4558;
  margin: 0 auto;
  width: 96%;
  border-radius: 0 0 32px 32px;
  backdrop-filter: blur(16px) brightness(34%);
  -webkit-backdrop-filter: blur(16px) brightness(34%);
  /* min-height: 112px; */
  height: auto;
}

.navContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 85%;
  padding: 2em 1em;
}

#navLogo {
  width: 152px;
  height: auto;
}

.navLinks {
  display: flex;
  align-items: center;
  gap: 3em;
}

.navlinkActive {
  color: white;
}

.navlinkActive::after {
  content: "";
  height: 4px;
  width: 100%;
  background-color: #4b4870;
  display: block;
  border-radius: 15px;
}

.profileImage {
  fill: white;
}

@media screen and (max-width: 767px) {
  .navLinks {
    display: flex;
    align-items: center;
    gap: 1.5em;
  }
}

.user-photo {
  position: relative;
}

.user-photo .avatar {
  width: 55px !important;
  height: 55px !important;
  border-radius: 100% !important;
  object-fit: cover !important;
}
