.e-kanban .e-kanban-header .e-header-cells {
  border-radius: 1em 1em 0em 0em;
  height: auto;
  font-size: 1rem;
  padding: 0.5em;
  padding-bottom: 0em;
}
.e-kanban
  .e-kanban-content
  .e-content-row:not(.e-swimlane-row)
  .e-content-cells {
  border-radius: 0em 0em 1em 1em;
}

.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card
  .e-card-header,
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card
  .e-card-header {
  padding: 0.5em !important;
  text-align: center;
}

.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card,
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card {
  min-height: unset !important;
}

.e-kanban .e-kanban-header .e-header-cells .e-header-wrap .e-header-title {
  justify-content: center !important;
}

.header-template-wrap .header-text {
  max-width: 140px;
  display: inline-block !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: wrap !important;
  text-align: center !important;
}

.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container {
  padding-top: unset !important;
}

.passed {
  background-color: #4caf50;
  color: #fff;
}

.failed {
  background-color: #f44336;
  color: #fff;
}

.awaiting {
  background-color: #ffc107;
  color: #fff;
}

.inprogress {
  background-color: #2196f3;
  color: #fff;
}

.incomplete {
  background-color: #9e9e9e;
  color: #fff;
}

.default {
  color: #000 !important;
}
