.footer {
    width: 100%;
    height: 360px;
    background-color: #171c45;
    /* margin-top: 8em; */
  }
  
  .footerText {
    font-family: roobertRegular;
    color: #bab5d1;
    font-size: 16px;
    list-style-type: none;
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 56px;
  }
  
  .footerIcons {
    width: 80%;
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  
  .iconBox {
    height: 40px;
    max-width: 80px;
    min-width: 64px;
    width: auto;
    float: right;
  }
  
  #twitter {
    background-image: url("../../../assets/images/twitter.svg");
  }
  
  #linkedIn {
    background-image: url("../../../assets/images/linkedIn.svg");
  }
  
  #glassDoor {
    background-image: url("../../../assets/images/glassdoor.svg");
  }
  
  .icon {
    height: 40px;
    width: 40px;
    float: right;
    border-radius: 16px;
    opacity: 0.64;
    transition-duration: 0.2s;
  }
  
  .icon:hover {
    border-radius: 20px;
    opacity: 0.8;
  }
  