.mainBanner {
  width: 100%;
  height: auto;
  z-index: 99;
  background-image: url("../../assets/images/background_1.svg");
  background-size: cover;
  background-position: center;
  padding-bottom: 104px;
}

.bodyClass {
  background-image: url("../../assets/images/Group 13.png");
  background-size: cover;
  background-position: top;
  margin-bottom: 10em;
}

.mainLogo {
  margin-top: 208px;
  width: 100%;
  display: inline-block;
}

#mainLogo {
  display: block;
  margin: auto;
}

.title1 {
  font-family: roobertSemiBold;
  font-weight: bold;
  font-size: 80px;
  text-align: center;
  width: 80%;
  margin-bottom: 0.5em;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.text1 {
  font-family: roobertRegular;
  font-size: 20px;
  text-align: center;
  line-height: 36px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 72%;
}

#missionText {
  color: white;
}

#missionHead {
  color: #969eff;
}

#doWhatText {
  color: #000000;
}

#doWhatHead {
  color: #000000;
}

.boxArea {
  width: 90%;
  margin: 0 auto;
  padding: 6em 1em 1em 1em;
}

.infoBox {
  width: 90%;
  padding: 2em;
  background-color: white;
  border-style: solid;
  border-color: #e6e6e6;
  border-radius: 16px;
  min-height: 420px;
  max-height: auto;
  margin-bottom: 3em;
}

.infoHead {
  width: 100%;
  text-align: center;
  font-family: roobertSemiBold;
  font-weight: bold;
  font-size: 24px;
  color: #2f00fe;
  margin-bottom: 40px;
}

.infoText {
  width: 100%;
  line-height: 36px;
  font-family: roobertRegular;
  font-size: 16px;
  text-align: center;
}

.uBox {
  height: auto;
}

#doWhatArea {
  clear: left;
  padding-top: 40px;
}

.buttonSmall {
  color: white;
  padding: 12px;
  border-radius: 24px;
  background-color: #2f00fe;
  width: 184px;
  text-align: center;
  font-family: roobertRegular;
  font-size: 16px;
  font-weight: regular;
  text-align: center;
  float: right;
  transition-duration: 0.2s;
  text-decoration: none;
}

.buttonSmall:hover {
  background-color: #2a3691;
  cursor: pointer;
}

#doWhatArea {
  margin-bottom: 120px;
}

.buttonBig {
  display: block;
  margin: auto;
  width: 360px;
  background-color: #2f00fe;
  color: white;
  font-family: roobertRegular;
  font-size: 24px;
  font-weight: regular;
  text-align: center;
  padding: 0.8em 0.5em;
  border-radius: 36px;
  transition-duration: 0.2s;
  text-decoration: none;
}

.buttonBig:hover {
  background-color: #2a3691;
  cursor: pointer;
}

