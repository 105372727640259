.apply-job-modal {
  position: relative;
}

.apply-job-modalheader {
  background-color: #ececec;
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.apply-job-modalheader .modal-title {
  padding-right: 16px;
}

.apply-job-modalheader .btn-close {
  margin: 0;
}

.form-group {
  width: 270px;
  margin-top: 3px;
}

.custom-form-group {
  width: -webkit-fill-available;
  margin-top: 3px;
}

.resume-form-group {
  width: fit-content;
  margin-top: 3px;
  height: 100%;
}

.resume-form-group,
.file-input-container {
  height: 100%;
}
.file-input-container {
  display: flex;
  align-items: center;
}
.tag {
  display: inline-block;
  background-color: #ececec;
  border-radius: 20px;
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.jobapply-model-footer {
  position: sticky;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
}

.modal-body {
  max-height: 67vh;
  overflow-y: auto;
  padding: 24px;
}

.footer-change-button {
  background-color: var(--primary-blue) !important;
  color: #fff !important;
}

.footer-save-button {
  border: 2px solid var(--primary-blue) !important;
  color: var(--primary-blue) !important;
}

.switch {
  display: inline-block;
  height: 24px;
  position: relative;
  width: 50px;
}

.switch input {
  display: none;
}

.slider {
  background-color: #ececec;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

.slider:before {
  background-color: #fff;
  bottom: 4px;
  content: "";
  height: 16px;
  left: 4px;
  position: absolute;
  transition: 0.4s;
  width: 16px;
}

input:checked + .slider {
  background-color: var(--primary-blue);
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.pdf-checkbox {
  visibility: hidden;
}

.selected-file .pdf-icon {
  border: 1px solid #4b487060;
  border-radius: 4px;
  padding: 0.3em;
  background-color: #4b48704b;
}

.pdf-icon {
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 0.3em;
  background-color: transparent;
  transition: all 0.3s ease;
}

.pdf-icon:hover {
  border: 1px solid #4b487060;
  background-color: #4b48704b;
}

.view-file-btn:hover {
  background-color: #4b4870;
  color: #fff;
}

.job-title-section {
  position: sticky;
  top: 0px;
  background: #fff;
}

.bg-status-green {
  background-color: #00ff9c;
}
.bg-status-red {
  background-color: #ff0000;
}
.bg-status-orange {
  background-color: #ff8800;
}
.bg-status-blue {
  background-color: #0059ff;
}
.bg-status-purple {
  background-color: #6f00ff;
}

.action-rounded {
  border-radius: 50% !important;
  padding: 0.25em !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
}

.action-close-btn {
  background-color: #4b487085 !important;
}
.action-check-btn {
  background-color: #4b4870 !important;
}

.custom-file-upload {
  width: 125px !important;
  height: 100px !important;
  padding: 2px !important;
}

.custom-file-upload > img {
  width: 40px !important;
}
