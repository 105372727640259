.hero-section {
  background-image: url("../../assets/images/background_1.svg");
  background-size: cover;
  background-position: center;
  min-height: 25vh;
}
.profile-container {
  position: relative;
  min-height: 100vh;
}

.user-profile-container {
  display: flex;
  justify-content: center;
  position: relative;
  height: 10rem;
  /* height: 100%; */
}
.profile-wrapper .form-floating > label {
  z-index: unset;
}

.user-profile-content {
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: auto;
  width: 250px;
  border-radius: 120px 120px 0 0;
  border: 1px solid #c9c8c85b;
  background-color: #fff;
  top: -150px;
  padding-bottom: 1em;
}

.profile-wrapper .optionContainer .option:hover {
  background-color: #1a1c46 !important;
  color: rgb(255, 255, 255) !important;
}

.user-profile-content > img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: 1.75em auto 0.5em auto;
  border: 1px solid #1a1c46;
  padding: 5px;
}

.user-details {
  text-align: center;
  padding: 0 1em;
}
.username,
.designation,
.address {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  line-height: 1.75rem;
}
.username {
  color: #1a1c46;
}

.address,
.designation {
  color: #4b4870;
}

.address {
  display: flex;
  gap: 0.25em;
  justify-content: center;
  align-items: center;
}

.profile-forms {
  padding: 0em 1em;
}

.btn-profile-navigation {
  border: 0;
  background-color: #fff;
  width: 100%;
  padding: 0.5em;
  font-size: 1.2rem;
  color: #4b4870;
}
.btn-profile-navigation.active {
  color: #1a1c46;
  font-weight: 600;
  border-bottom: 4px solid #1a1c46;
}

.detials-form-container {
  padding: 2em 1em;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 40vh;
  height: 100%;
  overflow: auto;
}
.profile-form-input.form-control {
  color: #4b4870;
}
.profile-form-input.form-control:focus,
.profile-form-input.form-select:focus {
  border-color: #4b4870;
  box-shadow: 0 0 0 0.25rem #4b487046 !important;
}

.profile-form-input.form-select:focus {
  border-width: 2px;
}

.detials-form-container .btn-navigation {
  min-width: 10rem;
}

.file-upload-container {
  display: flex;
  background-color: #4b487025;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 12rem;
  border-radius: 0.5em;
  border: 2px dashed #a29ed5;
  padding: 1em;
  cursor: pointer;
}

.file-upload-container > input {
  width: 0;
  height: 0;
}

.file-upload-container > img {
  width: 100px;
  margin: auto;
}
.file-upload-container > span {
  color: #a29ed5;
  font-size: 0.75rem;
  font-weight: bold;
}

.file-container {
  display: flex;
  gap: 1em;
  overflow: scroll;
  flex-wrap: wrap;
  padding: 0.75em 0;
}

.experience-container {
  overflow: auto;
  margin-bottom: 1em;
  padding: 0;
  max-height: 100vh !important;
  overflow-y: scroll !important;
}

.experience-card {
  margin-bottom: 0.7em;
}

.e-datepicker.e-popup-wrapper {
  width: auto !important;
}

.profile-wrapper
  .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(
    .e-float-icon-left
  ),
.e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
  .e-input-in-wrap,
.e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(
    .e-error
  ):not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(
    .e-warning
  ):not(.e-error)
  .e-input-in-wrap,
.e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
  .e-input-in-wrap,
.e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(
    .e-warning
  ):not(.e-error)
  .e-input-in-wrap {
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}

.profile-wrapper input.e-input,
.e-input-group input.e-input,
.e-input-group input,
.e-input-group.e-control-wrapper input.e-input,
.e-input-group.e-control-wrapper input,
.e-float-input input.e-input,
.e-float-input input,
.e-float-input.e-control-wrapper input.e-input,
.e-float-input.e-control-wrapper input,
.e-input-group input.e-input:focus,
.e-input-group.e-control-wrapper input.e-input:focus,
.e-float-input.e-control-wrapper input:focus,
.e-float-input input:focus,
.e-input-group.e-input-focus input.e-input,
.e-input-group.e-control-wrapper.e-input-focus input.e-input,
.e-float-input.e-control-wrapper.e-input-focus input,
.e-float-input.e-input-focus input {
  padding: 0;
  background: none;
}

.profile-wrapper .e-input-group .e-input-group-icon:last-child,
.e-input-group.e-control-wrapper .e-input-group-icon:last-child {
  color: #000 !important;
  background-color: transparent;
  padding-bottom: 3px;
}

.profile-wrapper
  .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(
    .e-float-input
  )::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(
    .e-float-input
  )::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::after {
  background: none !important;
  border: 0 !important;
}

.profile-wrapper
  .e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(
    .e-warning
  ):not(.e-error),
.e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left):not(
    .e-success
  ):not(.e-warning):not(.e-error),
.e-float-input.e-input-focus:not(.e-float-icon-left):not(.e-success):not(
    .e-warning
  ):not(.e-error),
.e-float-input.e-control-wrapper.e-input-focus:not(.e-float-icon-left):not(
    .e-success
  ):not(.e-warning):not(.e-error),
.e-input-group.e-float-icon-left.e-input-focus:not(.e-success):not(
    .e-warning
  ):not(.e-error)
  .e-input-in-wrap,
.e-input-group.e-control-wrapper.e-float-icon-left.e-input-focus:not(
    .e-success
  ):not(.e-warning):not(.e-error)
  .e-input-in-wrap {
  border: 2px solid #4b4870;
  box-shadow: 0 0 0 0.25rem #4b487046 !important;
}

.profile-wrapper .e-input-group input.e-input,
.e-input-group.e-control-wrapper input.e-input {
  font-size: unset;
}

.skill-label {
  background-color: #1a1c46;
  color: #fff;
  padding: 0.25em 0.5em;
  border-radius: 5px;
}

.file-name {
  width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.file-details {
  display: flex;
  position: relative;
}

.file-actions {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 80px;
}

.file-type-dropdown.dropdown-menu {
  transform: translate3d(-170px, 40px, 0px) !important;
}
